import React, { useEffect, useMemo } from 'react';
import { Switch, Route, Router, matchPath } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import { history } from '../store';
import { Imprint, Login, Logout, Profile, SiteSwitch } from '.';
import { DynamicImport, PrivateRoute, Header, Footer, Loading } from '../components';

export const ToolboxRouter = ({ modules = [], ...rest }) => {
  const { user, sites } = useSelector(
    state => ({
      user: state.authentication,
      sites: state.authentication.sites
    }),
    shallowEqual
  );

  /**
   * Check if the user is already logged in or has a valid remember status
   */
  useEffect(() => {
    // do nothing if login or logout in progress
    if (user.loggingIn || user.loggingOut) return;

    // check if the user is authenticated
    if (user.loggedIn === true) {
      // user has site selected and is not in smoove: redirect to smoove
      if (
        !!sites.selected &&
        !(
          matchPath(history.location.pathname, { path: '/one', exact: false }) ||
          ['/imprint', '/users'].includes(history.location.pathname)
        )
      ) {
        history.push('/one');
      }
      // user must select site
      else if (!sites.selected) {
        // skip site selection if there is only one site
        if (Object.keys(sites?.list).length === 1) {
          history.push('/one');
        } else {
          // only redirect to switch after login
          if (history.location.pathname === '/' || history.location.pathname === '/login') {
            history.push('/switch');
          }
        }
      }
    }
  }, [sites.selected, user, sites.list]);

  const moduleRoutes = useMemo(() => {
    if (modules.length > 0)
      return modules.map(module => (
        <Route key={module.path} path={module.path}>
          <DynamicImport load={module.content}>
            {Component =>
              Component === null ? <Loading /> : <Component {...(module?.wizards ? { wizards: module.wizards } : {})} />
            }
          </DynamicImport>
        </Route>
      ));
    else return null;
  }, [modules]);

  return (
    <Router history={history}>
      <Header />
      <Switch>
        <Route path={'/login'} exact>
          <Login />
        </Route>
        <Route path={'/switch'} exact>
          <SiteSwitch />
        </Route>
        <Route path={'/logout'} exact>
          <Logout />
        </Route>
        <Route path={'/imprint'} exact>
          <Imprint />
        </Route>
        <PrivateRoute path={'/profile'} exact>
          <Profile />
        </PrivateRoute>
        <Route path='/one'>{moduleRoutes}</Route>
        <Route path='/'>
          <Login />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
};
