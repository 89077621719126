import React from 'react';
import logo_ball from '../assets/img/smoove_logo.png';
import logo from '../assets/img/smoove.png';

export const HeaderLogo = ({ height = 50, type = 'small', animate = false }) => (
  <img
    src={type === 'full' ? logo : logo_ball}
    height={`${height}px`}
    // className={animate === true ? 'rotate-loop' : ''}
    alt='mindline analytics'
  />
);
