import React from 'react';
import PropTypes from 'prop-types';

import './Stone.scss'

const stones = {
  A:
    'M122.2,45.72c89.66-69.21,266.92-49.22,331.21,3.1s78,124.59-4.43,189.33S242.36,368.5,120.43,369s-122.82-80.7-116.17-129S59,94.49,122.2,45.72Z',
  B:
    'M83.56,381.31C1.76,293.52-23,134,26.07,65.43,85.56-17.74,317-18.8,406.35,60.32c77,68.2,145.64,321.89-7.65,398.15C285.08,515,172.45,476.71,83.56,381.31Z',
  C:
    'M7.49,396.55C-21.77,299.39,49.39,33.41,173.09,3.48S489.6,231.77,458.41,365.62C434.47,468.38,35.42,489.33,7.49,396.55Z',
  D:
    'M46.5,244.35c-1.78-88.67-29.71-188,64.73-227.9s221,4.88,252.73,59.42c61.64,106,14,128.14,67.4,176.47,53.63,48.5,93.55,187.11,11.08,203.07s-153-67.84-204.84-49.22S68.67,503.29,12.8,431.46C-25.75,381.9,48.26,332.26,46.5,244.35Z'
};

const Stone = ({ type, width, position, translate, color }) => {
  const style = {
    width: width,
    top: position[0],
    right: position[1],
    bottom: position[2],
    left: position[3],
    transform: position[4],
    overflow: 'hidden'
  };

  return (
    <div className='position-fixed' style={style}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 510 510'>
        <path
          className={`stone-smv stone-smv--${color}`}
          transform={`translate(${translate[0]},${translate[1]})`}
          d={stones[type]}
        />
      </svg>
    </div>
  );
};

Stone.propTypes = {
  type: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  position: PropTypes.array.isRequired
};

export { Stone };
