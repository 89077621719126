/**
 *
 * PrivateRoute
 * Higher Order Component that blocks navigation when the user is not logged in
 * and redirect the user to login page
 *
 * Wrap your protected routes to secure your containers
 */

import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

export const PrivateRoute = ({ children, path, exact }) => {
  const { user } = useSelector(state => ({
    user: state.authentication
  }), shallowEqual);

  /**
   * user is authenticated, render component
   */
  if (user.loggedIn) {
    return (
      <Route path={path} exact={exact}>
        {children}
      </Route>
    );

    /**
     * user is not authenticated, redirect to login
     */
  } else {
    return <Route render={props => <Redirect to={{ pathname: '/login', state: { from: props.location } }} />} />;
  }
};
