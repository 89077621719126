import React from 'react';
import PropTypes from 'prop-types';
import { Col, CustomInput, FormGroup } from 'reactstrap';
import { RowLabel } from './RowLabel';

// @Todo: Primary language/ locale must be set to define, in which language the questionnaire will be created/ displayed by default
export const CountrySelectRow = props => {
  const {
    title,
    name,
    value,
    countries,
    mainLanguage,
    setMainLanguage,
    add = () => console.log('Info: no add handler specified.'),
    remove = () => console.log('Info: no remove handler specified.'),
    placeholder = null,
    tooltip = null
  } = props;

  const unselectedCountries = Object.keys(countries)
    .filter(key => !Object.keys(value).includes(key))
    .reduce((res, key) => ({ ...res, [key]: countries[key] }), {});

  return (
    <FormGroup row>
      <RowLabel name={name} title={title} tooltip={tooltip} />

      <Col lg={8} xl={9}>
        {Object.keys(unselectedCountries).length > 0 && (
          <>
            <CustomInput type='select' name={name} id='pc_countries' className={'question'} value={0} onChange={add}>
              <option value={0} disabled={true} defaultValue={0}>
                {placeholder}
              </option>

              {Object.keys(unselectedCountries).map(countryId => (
                <option key={countryId} value={countryId}>
                  {unselectedCountries[countryId].label}
                </option>
              ))}
            </CustomInput>
            <label htmlFor='name'>
              <span />
            </label>
          </>
        )}

        {/* Seleted Countries */}
        <ul className='list-group list-group-flush'>
          {Object.values(value).map(country => (
            <li key={country.iso3} className={'list-group-item py-1 px-0 d-flex justify-content-between'}>
              <div className='d-flex flex-row no-gutters align-items-center'>
                <span className={`flag-icon flag-icon-squared flag-icon-${country.iso2} mr-2`} />
                <div>{country.label}</div>
              </div>

              <span>
                {/**
                 * @Todo: Add main language logic for multi language countries!
                 */}
                {country.locales.includes(mainLanguage) && <i className='fas fa-globe text-smv-green' />}
                {!country.locales.includes(mainLanguage) && (
                  <i
                    className='fal fa-globe text-smv-light pointer'
                    onClick={() => setMainLanguage(country.locales[0])}
                  />
                )}

                <i className='far fa-trash pointer text-smv p-1' onClick={() => remove(country.iso3)} />
              </span>
            </li>
          ))}
        </ul>
      </Col>
    </FormGroup>
  );
};

CountrySelectRow.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  countries: PropTypes.object.isRequired,
  add: PropTypes.func,
  remove: PropTypes.func,
  tooltip: PropTypes.string,
  placeholder: PropTypes.string
};
