import produce from 'immer';
import { persistReducer } from 'redux-persist';
import local from 'redux-persist/lib/storage';

import { settingsConstants } from '../constants';

const INITIAL_STATE = {
  locale: 'en_US',
  version: null,
  branch: null
};

const settingsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case settingsConstants.APP_SETTINGS_CHECK_VERSION_REQUEST:
      return state;
    case settingsConstants.APP_SETTINGS_CHECK_VERSION_SUCCESS:
      return produce(state, draft => {
        const { version, branch } = payload;
        draft.version = version;
        draft.branch = branch;
      });
    case settingsConstants.APP_SETTINGS_CHECK_VERSION_FAILURE:
      return state;

    case settingsConstants.APP_SETTINGS_SET_LOCALE_SUCCESS:
      return produce(state, draft => {
        const { locale } = payload;
        draft.locale = locale;
      });

    default:
      return { ...state };
  }
};

const persistConfig = {
  key: 'appSettings',
  storage: local,
  whitelist: ['locale']
};

export const settings = persistReducer(persistConfig, settingsReducer);
