import axios from 'axios';

import { userActions, userConstants } from './redux';
import { history, store } from './store';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 30000;
axios.defaults.baseURL = process.env?.REACT_APP_API ?? '';

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.__CANCEL__) {
      // error.message contains redux cancel action
      store.dispatch(error.message);
      return Promise.reject(error);
    } else if (error?.response) {
      const { status } = error?.response;

      if (status === 401) {
        const { authentication } = store.getState();

        if (
          !authentication.sessionexpired &&
          authentication.loggedIn &&
          !authentication.loggingIn &&
          !authentication.loggedOut &&
          !authentication.loggingOut &&
          authentication.remember === true
        ) {
          userActions
            .checkSession()
            .then(success => {
              if (success) history.push('/one');
              else history.push('/login');
            })
            .catch(error => {
              history.push('/login');
            });
        } else {
          store.dispatch({
            type: userConstants.USERS_SESSION_EXPIRED
          });
        }
      }
    }

    return Promise.reject(error);
  }
);
