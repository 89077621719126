import React from 'react';
import PropTypes from 'prop-types';
import { Col, CustomInput, FormGroup } from 'reactstrap';
import { RowLabel } from './RowLabel';

/**
 *
 * @param {string} title title for the row
 * @param {string} name form field name for the row (used for tooltip)
 * @param {object} value current values as object {cells: ###, cases: ###}
 * @param {object} change change handler as object {cells: () => {}), cases: () => {}}
 * @param {string} tooltip content of descriptive tooltip; default: null
 * @param {number} minCells minimum number of cells; default: 2
 * @param {number} maxCells maximum number of cells; default: 20
 * @param {number} stepCells steps to increase/ decrease cell count; default: 1
 * @param {number} minCases minimum number of cases; default: 50
 * @param {number} maxCases maximum number of cases; default: 1000
 * @param {number} stepCases steps to increase/ decrease case count; default: 10
 */
export const CellsCasesRow = props => {
  const {
    title,
    name,
    value,
    change = () => console.log('Info: no change handler specified.'),
    //    placeholder = null,
    tooltip = null,
    minCells = 2,
    maxCells = 20,
    stepCells = 1,
    minCases = 50,
    maxCases = 1000,
    stepCases = 10
    //    required = false
  } = props;

  return (
    <FormGroup row>
      <RowLabel name={name} title={title} tooltip={tooltip} />

      <Col lg={8} xl={9}>
        <div className='row'>
          {/*TEST CELLS - NUMERIC SELECT (1-8) */}
          <div className='col-6'>
            <CustomInput
              id='cellsCount'
              type='number'
              autoComplete='off'
              required
              className='question'
              value={value.cells}
              onChange={change.cells}
              step={stepCells}
              min={minCells}
              max={maxCells}
            />
            <label htmlFor='cellsCount'>
              <span>Number of cells</span>
            </label>
          </div>

          {/*NUMBER OF CASES PER CELL - NUMERIC */}
          <div className='col-6'>
            <CustomInput
              id='cases'
              type='number'
              autoComplete='off'
              required
              className='question'
              value={value.cases}
              onChange={change.cases}
              step={stepCases}
              min={minCases}
              max={maxCases}
            />
            <label htmlFor='cases'>
              <span>Cases per cell</span>
            </label>
          </div>
        </div>
      </Col>
    </FormGroup>
  );
};

CellsCasesRow.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  change: PropTypes.object.isRequired,
  tooltip: PropTypes.string,
  placeholder: PropTypes.string,
  minCells: PropTypes.number,
  maxCells: PropTypes.number,
  stepCells: PropTypes.number,
  minCases: PropTypes.number,
  maxCases: PropTypes.number,
  stepCases: PropTypes.number
};
