import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { GroupRules } from '.';
import produce from 'immer';
import { nanoid } from 'nanoid';

import './QueryBuilder.scss';

const rootRule = {
  isModified: false,
  rootElement: '_root',
  elements: {
    _root: {
      id: '_root',
      parent: false,
      condition: 'and',
      not: false,
      rules: ['_initialRule']
    },
    _initialRule: {
      id: '_initialRule',
      parent: '_root',
      operator: null,
      value: null
    }
  }
};

const actionTypes = {
  SAVE: 'SAVE',
  ADD_RULE: 'ADD_RULE',
  ADD_GROUP_RULE: 'ADD_GROUP_RULE',
  REMOVE_RULE: 'REMOVE_RULE',
  UPDATE_RULE: 'UPDATE_RULE'
};

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SAVE: {
      return produce(state, draftState => {
        draftState.isModified = false;
      });
    }

    case actionTypes.ADD_RULE: {
      const rule = payload;
      rule.id = nanoid();
      return produce(state, draftState => {
        draftState.isModified = true;
        draftState.elements[rule.id] = rule;
        draftState.elements[rule.parent].rules.push(rule.id);
      });
    }

    case actionTypes.ADD_GROUP_RULE: {
      const rule = payload;
      const defaultRule = {
        id: nanoid(),
        parent: rule.id,
        operator: null,
        value: null
      };

      rule.id = nanoid();
      rule.rules = [defaultRule.id];

      return produce(state, draftState => {
        draftState.isModified = true;
        draftState.elements[defaultRule.id] = defaultRule;
        draftState.elements[rule.id] = rule;
        draftState.elements[rule.parent].rules.push(rule.id);
      });
    }

    case actionTypes.REMOVE_RULE: {
      const rule = payload;
      return produce(state, draftState => {
        draftState.isModified = true;

        if (rule.parent) {
          const parentElement = draftState.elements[rule.parent];
          parentElement.rules = parentElement.rules.filter(id => id !== rule.id);
          delete draftState.elements[rule.id];
          rule?.rules && rule.rules.forEach(id => delete draftState.elements[id]);
        }
      });
    }

    case actionTypes.UPDATE_RULE: {
      const { ruleid, name, value } = payload;
      return produce(state, draftState => {
        // source id change resets the operator and options
        if (name === 'sourceId') {
          draftState.elements[ruleid].operator = null;
          draftState.elements[ruleid].value = null;
        }
        // rule.value logic when operator changes from Multiple to Single value.
        // Operator are classified into two types
        // Single value - EQUALS, NOT_EQUALS
        // Multiple value - ALL_OF , NONE_OF, ONE_OF
        if (name === 'operator') {
          // if operator is empty value should also be empty
          if (!value) {
            draftState.elements[ruleid].value = undefined;
            return;
          }

          if (value === 'EQUALS' || value === 'NOT_EQUALS') {
            if (Array.isArray(draftState.elements[ruleid]?.value)) {
              if (draftState.elements[ruleid].value.length === 1) {
                // only one value present in rule.value
                draftState.elements[ruleid].value = draftState.elements[ruleid].value[0];
              } else {
                // From Multiple to Single value conversion, clear all values
                draftState.elements[ruleid].value = null;
              }
            }
          } else {
            if (draftState.elements[ruleid]?.value && !Array.isArray(draftState.elements[ruleid]?.value)) {
              draftState.elements[ruleid].value = [draftState.elements[ruleid].value];
            }
          }
        }

        draftState.elements[ruleid][name] = value;
        draftState.isModified = true;
      });
    }
    default:
      return state;
  }
};

export function QueryBuilder({ name, fields, rulesList, setStateToParent, readOnly = false }) {
  const [mainRulesList, dispatch] = useReducer(reducer, rulesList ?? rootRule);

  useEffect(() => {
    if (mainRulesList.isModified) {
      dispatch({ type: actionTypes.SAVE });
      setStateToParent(mainRulesList);
    }
  }, [mainRulesList, setStateToParent]);

  return (
    <div className='querybuilder'>
      <GroupRules
        readOnly={readOnly}
        name={name}
        rulesList={mainRulesList}
        fields={fields}
        group={mainRulesList.elements[mainRulesList.rootElement]}
        level={0}
        dispatch={dispatch}
      />
    </div>
  );
}

QueryBuilder.propTypes = {
  rulesList: PropTypes.shape({
    rootElement: PropTypes.string.isRequired,
    elements: PropTypes.object.isRequired
  })
};
