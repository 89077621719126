import React from 'react';
import { useSelector } from 'react-redux';

import { GroupHeader, Rule } from '.';

export const GroupRules = ({ name, rulesList, fields, group, level, dispatch, readOnly = false }) => {
  const [translations, locale] = useSelector(state => [
    state.survey.translations,
    state.projectSettings[state.project.id]?.selectedLocale || state.survey.locales.main
  ]);

  return (
    <div className='querybuilder__group-rules'>
      <GroupHeader group={group} dispatch={dispatch} name={name} readOnly={readOnly} />

      {group.rules.map((id, index) => {
        const element = rulesList.elements[id];
        if (element.hasOwnProperty('rules')) {
          return (
            <GroupRules
              key={element.id}
              readOnly={readOnly}
              rulesList={rulesList}
              fields={fields}
              group={element}
              dispatch={dispatch}
            />
          );
        } else {
          return (
            <Rule
              key={element.id}
              readOnly={readOnly}
              fields={fields}
              rule={element}
              dispatch={dispatch}
              translations={translations}
              locale={locale}
            />
          );
        }
      })}
    </div>
  );
};
