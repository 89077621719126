import React, { useCallback } from 'react';
import { UncontrolledTooltip, ButtonGroup, Button } from 'reactstrap';
import { useIntl } from 'react-intl';

export const GroupHeader = ({ group, dispatch, name, readOnly = false }) => {
  const disabled = group.rules.length <= 1 || readOnly;

  const intl = useIntl();

  const updateRule = useCallback(
    (name, value) => {
      dispatch({
        type: 'UPDATE_RULE',
        payload: {
          ruleid: group.id,
          name,
          value
        }
      });
    },
    [group.id, dispatch]
  );

  return (
    <div className='row querybuilder__group-header'>
      <div className={'col-12 mb-2'}>
        <ButtonGroup size={'sm'}>
          <Button
            disabled={disabled}
            outline={group.condition === 'or'}
            color={'secondary'}
            onClick={() => !disabled && group.condition !== 'and' && updateRule('condition', 'and')}
          >
            {intl.formatMessage({ id: `common.querybuilder.and` })}
          </Button>
          <Button
            disabled={disabled}
            outline={group.condition === 'and'}
            color={'secondary'}
            onClick={() => !disabled && group.condition !== 'or' && updateRule('condition', 'or')}
          >
            {intl.formatMessage({ id: `common.querybuilder.or` })}
          </Button>
        </ButtonGroup>
        <Button
          className='ml-2'
          size={'sm'}
          disabled={readOnly}
          color={'danger'}
          outline={!group.not}
          onClick={() => updateRule('not', !group.not)}
        >
          {intl.formatMessage({ id: `common.querybuilder.not` })}
        </Button>
        <Button
          className='ml-4'
          size={'sm'}
          disabled={readOnly}
          color={'primary'}
          onClick={() => {
            dispatch({
              type: 'ADD_RULE',
              payload: {
                parent: group.id,
                operator: null,
                value: null
              }
            });
          }}
          id={name + '_addrule_' + group.id}
        >
          <i className='fal fa-plus mr-1' />
          <span>{intl.formatMessage({ id: `common.querybuilder.rule` })}</span>
        </Button>
        <Button
          className='ml-2'
          disabled={readOnly}
          size={'sm'}
          color={'primary'}
          onClick={() => {
            dispatch({
              type: 'ADD_GROUP_RULE',
              payload: {
                parent: group.id,
                condition: 'and',
                not: false
              }
            });
          }}
          id={name + '_addgroup_' + group.id}
        >
          <i className='fal fa-plus mr-1' />
          <span>{intl.formatMessage({ id: `common.querybuilder.group` })}</span>
        </Button>

        {group.id !== '_root' && (
          <Button
            disabled={readOnly}
            className='ml-4'
            color={'danger'}
            size={'sm'}
            outline={true}
            // className={'btn btn-smv-red'}
            onClick={() => {
              dispatch({ type: 'REMOVE_RULE', payload: group });
            }}
            id={name + '_rmgroup_' + group.id}
          >
            <i className='fal fa-trash-alt' />
            <UncontrolledTooltip target={name + '_rmgroup_' + group.id}>
              {intl.formatMessage({ id: `common.querybuilder.remove` })}
            </UncontrolledTooltip>
          </Button>
        )}
      </div>
    </div>
  );
};
