import React, { useEffect, useState } from 'react';
import AutosizeInput from 'react-input-autosize';
import classNames from 'classnames';
import './InlineEdit.scss';

export const InlineEdit = ({
  defaultValue = '',
  saveFn,
  onChange = null,
  placeholder = 'Enter text...',
  className = '',
  disabled = false
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <AutosizeInput
      className={classNames('inline-edit', className, { 'inline-edit--empty': !value })}
      name='inline-edit'
      value={!onChange ? value : defaultValue}
      placeholder={placeholder}
      disabled={disabled}
      onChange={e => {
        !onChange ? setValue(e.target.value) : onChange(e.target.value);
      }}
      onBlur={() => {
        if (value !== defaultValue) {
          saveFn(value);
        }
      }}
    />
  );
};
