import React from 'react';
import classNames from 'classnames';
import './iconprovider.scss';
import { IconProvider } from './IconProvider';

export const IconProviderWithName = ({ icon, filled = false, clickable = false, name, dragable = false }) => {
  let classes = classNames('iconprovider_with_name', {
    'iconprovider_with_name--clickable': clickable,
    'iconprovider_with_name--dragable': dragable
  });

  return (
    <div className={classes}>
      <IconProvider icon={icon} filled={filled} clickable={false} />
      <div className='iconprovider_with_name__name'>{name}</div>
    </div>
  );
};
