import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Button, Input, UncontrolledTooltip } from 'reactstrap';
import { useIntl } from 'react-intl';
import { isEqualWith } from 'lodash';

import { stripHtml } from '../../helpers';
import { MultipleSelectDropdown } from '.';

const ruleConfig = {
  single_vector: {
    operators: [
      { value: 'EQUALS', label: `common.querybuilder.equals` },
      { value: 'ONE_OF', label: `common.querybuilder.one-of` },
      { value: 'NONE_OF', label: `common.querybuilder.none-of` }
    ],
    inputType: 'multiselect'
  },
  multiple_vector: {
    operators: [
      { value: 'ONE_OF', label: `common.querybuilder.one-of` },
      { value: 'ALL_OF', label: `common.querybuilder.all-of` },
      { value: 'NONE_OF', label: `common.querybuilder.none-of` }
    ],
    inputType: 'multiselect'
  },
  single_input: {
    operators: [
      { value: 'EQUALS', label: `common.querybuilder.equals` },
      { value: 'GE', label: `common.querybuilder.greater-than-or-equal` },
      { value: 'LE', label: `common.querybuilder.less-than-or-equal` }
    ],
    inputType: 'inputTypeFromInput'
  },
  single_matrix: {
    operators: [
      { value: 'EQUALS', label: `common.querybuilder.equals` },
      { value: 'NOT_EQUALS', label: `common.querybuilder.not-equals` },
      { value: 'ONE_OF', label: `common.querybuilder.one-of` },
      { value: 'NONE_OF', label: `common.querybuilder.none-of` }
    ],
    inputType: 'multiselect',
    choice: true
  },
  multiple_matrix: {
    operators: [
      { value: 'ONE_OF', label: `common.querybuilder.one-of` },
      { value: 'ALL_OF', label: `common.querybuilder.all-of` },
      { value: 'NONE_OF', label: `common.querybuilder.none-of` }
    ],
    inputType: 'multiselect',
    choice: true
  },
  custom_shelf: { operators: [], input: '' }
};

export const Rule = ({ fields, rule, dispatch, translations, locale, readOnly = false }) => {
  const [sourceConfig, setSourceConfig] = useState({ qtype: '' });

  const sourceKey = useMemo(() => {
    const { sourceId, sourceLoopConfig } = rule;
    if (!sourceId) return null;

    const sourceElement = fields.find(field => {
      if (!sourceLoopConfig) return field.element.id === sourceId;
      else return field.element.id === sourceId && isEqualWith(sourceLoopConfig, field?.sourceLoopConfig);
    });

    if (!sourceElement) return null;
    setSourceConfig(sourceElement.element.config);

    return sourceElement.id;
  }, [fields, rule]);

  const intl = useIntl();

  useEffect(() => {
    if (!rule.id) {
      dispatch({ type: 'ADD_RULE', payload: rule });
    }
  }, [rule, dispatch]);

  const updateRule = useCallback(
    (name, value) => {
      dispatch({
        type: 'UPDATE_RULE',
        payload: {
          ruleid: rule.id,
          name,
          value
        }
      });
    },
    [rule.id, dispatch]
  );

  return (
    <div className='querybuilder__group-rule'>
      <div className={'row pl-3'}>
        {/* Source question */}
        <div className='querybuilder__group-rule-line-container pb-2'>
          <Input
            disabled={readOnly}
            type='select'
            name={`rule${rule.id}_var`}
            value={sourceKey ?? ''}
            onChange={({ target }) => {
              if (!target?.value) return;
              const sourceElement = fields.find(field => field.id === target.value);
              if (!sourceElement) return;

              updateRule('sourceId', sourceElement.element.id);
              if (!!sourceElement?.sourceLoopConfig) updateRule('sourceLoopConfig', sourceElement.sourceLoopConfig);
            }}
          >
            <option value={''} disabled>
              {intl.formatMessage({ id: `common.querybuilder.select-source`, defaultMessage: 'select source' })}
            </option>
            <SourceOptions fields={fields} />
          </Input>
        </div>

        {/* Choice for single matrix and multiple matrix */}
        {sourceConfig.qtype && ruleConfig[sourceConfig.qtype] && ruleConfig[sourceConfig.qtype].choice && (
          <div className='col-md-2  pb-2'>
            <Input
              disabled={readOnly}
              type='select'
              name={`rule${rule.id}_choice`}
              value={rule?.choiceId ?? ''}
              onChange={e => updateRule('choiceId', e.target.value)}
            >
              <option value={''} disabled>
                {intl.formatMessage({ id: `common.querybuilder.select-item`, defaultMessage: 'select item' })}
              </option>
              {sourceConfig?.choices?.order?.length > 0 &&
                sourceConfig?.choices?.order?.map(id => (
                  <option key={id} value={sourceConfig.choices.list[id].id}>
                    {stripHtml(translations?.[locale]?.[sourceConfig.choices.list[id].label] ?? '')}
                  </option>
                ))}
            </Input>
          </div>
        )}

        {/* Condition */}
        {ruleConfig[sourceConfig.qtype] && (
          <div className='col-md-2 pr-0 pb-2'>
            <Input
              disabled={readOnly}
              type='select'
              name={`rule${rule.id}_opr`}
              value={rule.operator || ''}
              onChange={e => updateRule('operator', e.target.value)}
            >
              <option value={''} disabled>
                {intl.formatMessage({ id: `common.querybuilder.select-condition`, defaultMessage: 'select condition' })}
              </option>
              {ruleConfig[sourceConfig.qtype].operators.map(operator => (
                <option key={operator.value} value={operator.value}>
                  {intl.formatMessage({ id: operator.label })}
                </option>
              ))}
            </Input>
          </div>
        )}

        {/* Value */}
        {ruleConfig[sourceConfig.qtype] && (
          <div className='col-md-4 pr-0 pb-2'>
            {!rule?.operator ? (
              <Input
                disabled={true}
                placeholder={intl.formatMessage({ id: `common.querybuilder.select-value` })}
              ></Input>
            ) : (
              <InputSelector
                readOnly={readOnly}
                key={sourceConfig.text}
                rule={rule}
                dispatch={dispatch}
                translations={translations}
                locale={locale}
                inputType={
                  ruleConfig[sourceConfig.qtype].inputType === 'inputTypeFromInput'
                    ? sourceConfig.input
                    : rule.operator === 'EQUALS' || rule.operator === 'NOT_EQUALS'
                    ? 'singleselect'
                    : ruleConfig[sourceConfig.qtype].inputType
                }
                choiceList={
                  ruleConfig[sourceConfig.qtype].choice ? sourceConfig.scale.list : sourceConfig?.choices?.list
                }
              />
            )}
          </div>
        )}

        <div className='col-auto pb-2 d-flex align-items-center'>
          <Button
            disabled={readOnly}
            color={'danger'}
            size={'sm'}
            outline={true}
            onClick={e => {
              dispatch({ type: 'REMOVE_RULE', payload: rule });
            }}
          >
            <i className='fal fa-trash-alt' id={'rmrule_' + rule.id} />
            <UncontrolledTooltip target={'rmrule_' + rule.id}>
              {intl.formatMessage({ id: `common.querybuilder.remove` })}
            </UncontrolledTooltip>
          </Button>
        </div>
      </div>
    </div>
  );
};

const SourceOptions = ({ fields }) => {
  return fields.map(field => (
    <option key={field.id} value={field.id}>
      {field.element?.config?.varname ?? ""}
      {field.element?.config?.varname ? " - " : ""}
      {field.label}
    </option>
  ));
};

const InputSelector = ({ rule, dispatch, inputType, choiceList, translations, locale, readOnly = false }) => {
  const updateRule = useCallback(
    (name, value) => {
      dispatch({
        type: 'UPDATE_RULE',
        payload: {
          ruleid: rule.id,
          name,
          value
        }
      });
    },
    [rule.id, dispatch]
  );

  const intl = useIntl();

  switch (inputType) {
    case 'number':
    case 'text': {
      return (
        <Input
          disabled={readOnly}
          type={inputType}
          name={`rule_${rule.id}`}
          value={rule?.value ?? ''}
          onChange={e => updateRule('value', e?.target?.value ?? null)}
        />
      );
    }
    case 'singleselect': {
      // operator "EQUALS" is selected
      return (
        <Input
          // disabled={readOnly}
          type='select'
          value={rule?.value || ''}
          placeholder={intl.formatMessage({ id: `common.querybuilder.select-value`, defaultMessage: 'select values' })}
          onChange={e => updateRule('value', e?.target?.value ?? null)}
        >
          <option value={''} disabled>
            {intl.formatMessage({ id: `common.querybuilder.select-value` })}
          </option>
          {Object.values(choiceList).map(item => (
            <option key={item.id} value={item.id} disabled={readOnly}>
              {stripHtml(translations?.[locale]?.[item?.label] ?? item?.label ?? '')}
            </option>
          ))}
        </Input>
      );
    }
    case 'multiselect': {
      // const options = [
      //   { id: 1, label: 'Thing 1' } ];
      const options = Object.values(choiceList).map(item => {
        return {
          id: item.id,
          label: stripHtml(translations[locale]?.[item.label] || item.label)
        };
      });

      return (
        <MultipleSelectDropdown
          readOnly={readOnly}
          options={options}
          values={rule.value}
          onChange={value => updateRule('value', value ?? null)}
          placeholder={intl.formatMessage({ id: `common.querybuilder.select-value`, defaultMessage: 'select values' })}
        />
      );
    }
    default:
      return <div>input type not implemented or invalid</div>;
  }
};
