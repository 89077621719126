import React from 'react';
import { useIntl } from 'react-intl';

import { TextInputRow } from 'toolbox-common/src';

export const Username = ({ state, onChange }) => {
  const intl = useIntl();

  return (
    <TextInputRow
      name={'username'}
      title={intl.formatMessage({ id: 'core.page.login.user.label', defaultMessage: 'Username' })}
      value={state.username}
      placeholder={intl.formatMessage({
        id: 'core.page.login.user.placeholder',
        defaultMessage: 'Enter username'
      })}
      change={onChange}
      required
      autoFocus
    />
  );
};
