import axios from 'axios';

import 'toolbox-shell/src/axiosConfig';
import { getFormData } from 'toolbox-common/src/helpers';
import { store } from '../../store';
import { userConstants } from '../constants';

export const userActions = {
  login,
  logout,
  resetPassword,
  getUserDetails,
  checkSession,
  recover,
  switchSite
};

function login({ username, password, remember, captcha }) {
  store.dispatch({
    type: userConstants.USERS_LOGIN_REQUEST,
    payload: { user: { username } }
  });

  const pca = {
    program: 'User',
    controller: 'XIndex',
    action: 'login'
  };

  const fd = new FormData();

  fd.append('username', username);
  fd.append('pw', password);

  if (captcha) fd.append('captcha', captcha);
  if (remember) fd.append('rememberme', remember ? 1 : 0);

  return axios
    .post(`/jsonrpc.php`, fd, { params: pca })
    .then(res => {
      const { result } = res.data;
      const actionResult = result.program.User.XIndex.login;

      if (actionResult?.status === true) {
        result.remember = remember === true;

        /**
         * The parent site only handles the first login,
         * all data is stored in the subsites
         */
        const sites = result.toolbox?.sites ?? {};
        if (sites?.smoove) delete sites.smoove;
        if (Object.keys(sites).length === 0) {
          console.log('No sites available');
        }

        store.dispatch({
          type: userConstants.USERS_LOGIN_SUCCESS,
          payload: {
            acls: result.toolbox.navi.actions,
            sites: sites,
            remember: remember
          }
        });

        /**
         * Call site switch if only one site available
         */
        const siteKeys = Object.keys(sites);
        if (siteKeys.length === 1) {
          userActions.switchSite(siteKeys[0]);
        }

        return true;
      } else {
        // response from api indicates that password is expired
        const pwexpired = !!actionResult?.messages?.find(message => message.code === 'pwexpired') ?? false;
        const accountexpired =
          !!actionResult?.messages?.find(
            message => message.code === 'error' && message.message.includes('Account expired')
          ) ?? false;
        const captcha = actionResult?.data?.captcha?.required === true;
        const captchaInvalid = !!actionResult?.data?.captcha?.errors;

        store.dispatch({
          type: userConstants.USERS_LOGIN_FAILURE,
          payload: {
            pwexpired: pwexpired,
            accountexpired: accountexpired,
            captcha: captcha,
            captchaInvalid: captchaInvalid,
            newPasswordInvalid: false,
            error: ''
          }
        });

        return false;
      }
    })
    .catch(err => {
      store.dispatch({
        type: userConstants.USERS_LOGIN_FAILURE,
        payload: { error: err }
      });
    });
}

function checkSession() {
  const data = {
    program: 'User',
    controller: 'XIndex',
    action: 'home'
  };

  store.dispatch({
    type: userConstants.REMEMBER_LOGIN_REQUEST
  });

  return axios
    .get(`/jsonrpc.php`, { params: data })
    .then(response => {
      const { toolbox } = response.data.result;

      /**
       * The parent site only handles the first login,
       * all data is stored in the subsites
       */
      const sites = toolbox?.sites ?? {};
      if (sites?.smoove) delete sites.smoove;
      if (Object.keys(sites).length === 0) {
        throw new Error('No sites available');
      }

      store.dispatch({
        type: userConstants.REMEMBER_LOGIN_SUCCESS,
        payload: {
          acls: toolbox.navi.actions,
          sites: sites
        }
      });

      /**
       * Call site switch if only one site available
       */
      const siteKeys = Object.keys(sites);
      if (siteKeys.length === 1) {
        userActions.switchSite(siteKeys[0]);
      }

      return Promise.resolve(true);
    })
    .catch(e => {
      store.dispatch({
        type: userConstants.REMEMBER_LOGIN_FAILURE,
        payload: { error: e?.message ?? e }
      });

      return Promise.reject();
    });
}

function switchSite(sitecode) {
  store.dispatch({
    type: userConstants.USERS_SWITCHSITE_REQUEST
  });

  const pca = {
    program: 'User',
    controller: 'XIndex',
    action: 'changesite'
  };

  const fd = new FormData();

  fd.append('code', sitecode);

  return axios
    .post(`/jsonrpc.php`, fd, { params: pca })
    .then(response => {
      const { sitecode } = response.data.result.toolbox.config.backend;
      const { actions } = response.data.result.toolbox.navi;

      /**
       * The parent site only handles the first login,
       * all data is stored in the subsites
       */
      const sites = response.data.result?.toolbox?.sites ?? {};
      if (sites?.smoove) delete sites.smoove;
      if (Object.keys(sites).length === 0) {
        throw new Error('No sites available');
      } else {
        store.dispatch({
          type: userConstants.USERS_SWITCHSITE_SUCCESS,
          payload: { sitecode, acls: actions, sites }
        });

        return true;
      }
    })
    .catch(() => {
      store.dispatch({
        type: userConstants.USERS_SWITCHSITE_FAILURE
      });

      return false;
    });
}

function getUserDetails() {
  const pca = {
    program: 'User',
    controller: 'XUser',
    action: 'edit'
  };

  store.dispatch({
    type: userConstants.USERS_DETAILS_REQUEST
  });

  return axios
    .get(`/jsonrpc.php`, { params: pca })
    .then(res => {
      const fields = ['firstname', 'lastname', 'email', 'username', 'expirationtime', 'passmtime'];
      const data = res.data.result.program.User.XUser.edit.data;

      const userDetails = {};
      fields.forEach(field => (userDetails[field] = data[field].value));
      store.dispatch({
        type: userConstants.USERS_DETAILS_SUCCESS,
        payload: { user: userDetails }
      });
    })
    .catch(error =>
      store.dispatch({
        type: userConstants.USERS_DETAILS_FAILURE,
        payload: { error }
      })
    );
}

function logout() {
  store.dispatch({
    type: userConstants.USERS_LOGOUT_REQUEST
  });

  const pca = {
    program: 'User',
    controller: 'XIndex',
    action: 'logout'
  };

  /**
   * @todo: tbd: change back to parent site `smoove` before logout
   *        otherwise, the php session will keep the last selected site and
   *        a new login with another user account (without access to the stored
   *        site) would fail.
   */

  axios
    .post(`/jsonrpc.php`, null, { params: pca })
    .then(res => {
      if (res.data.result.program.User.XIndex.logout.status === true) {
        store.dispatch({
          type: userConstants.USERS_LOGOUT_SUCCESS
        });
      } else {
        throw Error(`Request failed with status code ${res.status}`);
      }
    })
    .catch(err => {
      store.dispatch({
        type: userConstants.USERS_LOGOUT_FAILURE,
        payload: { err }
      });
    });
}

function recover(username, email, captcha) {
  return dispatch => {
    dispatch(request({ username, email }));

    const pca = {
      program: 'User',
      controller: 'XIndex',
      action: 'recover'
    };

    const fd = new FormData();

    fd.append('username', username);
    fd.append('email', email);
    fd.append('captcha', captcha);
    fd.append('pw', '');

    axios
      .post(`/jsonrpc.php`, fd, { params: pca })
      .then(recoverResult => {
        // history.push('/');
        dispatch(success(recoverResult.data));
      })
      .catch(err => {
        dispatch(failure(err));
      });
  };

  function request(user) {
    return { type: userConstants.LOGOUT_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGOUT_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGOUT_FAILURE, error };
  }
}

function resetPassword({ username, pw, pw1, pw2, captcha }) {
  store.dispatch({
    type: userConstants.USERS_RESET_PASSWORD_REQUEST,
    payload: {}
  });

  const pca = {
    program: 'User',
    controller: 'XIndex',
    action: 'login'
  };

  const fd = getFormData({
    username,
    captcha,
    pw,
    pw1,
    pw2
  });

  //Todo: Handle error response
  return axios
    .post(`/jsonrpc.php`, fd, { params: pca })
    .then(res => {
      const { result } = res.data;
      const actionResult = result.program.User.XIndex.login;

      if (actionResult?.status ?? false) {
        /**
         * The parent site only handles the first login,
         * all data is stored in the subsites
         */
        const sites = res.data.result?.toolbox?.sites ?? {};
        if (sites?.smoove) delete sites.smoove;
        if (Object.keys(sites).length === 0) {
          throw new Error('No sites available');
        }

        store.dispatch({
          type: userConstants.USERS_RESET_PASSWORD_SUCCESS,
          payload: {
            acls: result.toolbox.navi.actions,
            sites: sites
          }
        });

        /**
         * Call site switch if only one site available
         */
        const siteKeys = Object.keys(sites);
        if (siteKeys.length === 1) {
          userActions.switchSite(siteKeys[0]);
        }
      } else {
        const pwexpired = !!actionResult?.messages?.find(message => message.code === 'pwexpired') ?? false;
        const captchaInvalid = !!actionResult?.data?.captcha?.errors;
        const captcha = actionResult?.data?.captcha?.required === true;
        const newPasswordInvalid = !!actionResult?.data?.pw1?.errors;

        store.dispatch({
          type: userConstants.USERS_RESET_PASSWORD_FAILURE,
          payload: {
            pwexpired: pwexpired,
            captcha: captcha,
            captchaInvalid: captchaInvalid,
            newPasswordInvalid: newPasswordInvalid,
            error: false
          }
        });
      }
    })
    .catch(err => {
      store.dispatch({
        type: userConstants.USERS_LOGIN_FAILURE,
        payload: { error: err }
      });
    });
}
