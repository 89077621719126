import React, { useEffect, useState, useRef } from 'react';
import { CustomInput, Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import PropTypes from 'prop-types';
import './MultipleSelectDropdown.scss';

export const MultipleSelectDropdown = ({ options, values = [], onChange, placeholder = 'select' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [internalValues, setInternalValues] = useState(values);

  useEffect(() => {
    if (Array.isArray(values)) {
      setInternalValues(values);
    } else {
      setInternalValues([]);
    }
  }, [values]);

  useEffect(() => {
    if (onChange) onChange(internalValues);
  }, [internalValues]);

  const toggleOpened = () => setIsOpen(prevState => !prevState);

  const handleChange = (item, isSelected) => {
    if (isSelected) setInternalValues(prevState => prevState.filter(id => id !== item.id));
    else setInternalValues(prevState => [...prevState, item.id]);
  };

  const selectedElements = options
    .filter(item => internalValues.includes(item.id))
    .map(item => (
      <div key={item.id} className='multipleselect__selected-item d-flex'>
        <span
          className='multipleselect__selected-item-close badge-smv-blue-outline'
          onClick={e => {
            e.stopPropagation();
            setInternalValues(internalValues.filter(value => value !== item.id));
          }}
        >
          &times;
        </span>
        <div className='multipleselect__selected-item-label badge-smv-blue-outline' title={item.label}>{item.label}</div>
      </div>
    ));

  //calculate width of container to show the correct amount of selectedElements
  //one element shoud be about 80px wide
  let multiselectContainer = useRef(null);
  let maxSelectedElements = Math.floor((multiselectContainer.current?.offsetWidth - 60) / 80);

  return (
    <Dropdown isOpen={isOpen} toggle={toggleOpened}>
      <DropdownToggle tag={'div'} caret={false}>
        <div
          ref={multiselectContainer}
          className={`multipleselect__selected-container ${
            selectedElements.length === 0 ? 'multipleselect__selected-container--empty' : ''
          }`}
        >
          {selectedElements.length === 0 && placeholder}
          {selectedElements.length <= maxSelectedElements && selectedElements}
          {selectedElements.length > maxSelectedElements && (
            <>
              {selectedElements.map((el, idx) => {
                return idx < maxSelectedElements ? el : null;
              })}
              <div className='multipleselect__additional-items'>+{selectedElements.length - maxSelectedElements}</div>
            </>
          )}
        </div>
      </DropdownToggle>

      <DropdownMenu>
        {options.map(item => (
          <DropdownItem
            key={item.id}
            tag={'div'}
            toggle={null}
            className={'p-0'}
            onClick={() => handleChange(item, internalValues.includes(item.id))}
          >
            <div className={'w-100 h-100 p-1 pl-3 pr-3'}>
              <CustomInput
                disabled={true}
                type='checkbox'
                id={item.id.toString()}
                label={item.label}
                checked={internalValues.includes(item.id)}
              />
            </div>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

MultipleSelectDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};
