import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Col, Row } from 'reactstrap';

import { Stone } from 'toolbox-common';
import { userActions } from '../redux';

import NoSitePlacehoder from '../assets/img/no_site_placeholder.svg';
import './SiteSwitch.scss';

export const SiteSwitch = () => {
  const { sites } = useSelector(state => ({
    sites: state.authentication.sites
  }));

  const intl = useIntl();

  return (
    <>
      <Stone type={'D'} color={'green'} width={'400px'} position={['20%', null, null, 0]} translate={[-250, 0]} />
      <Stone type={'B'} color={'orange'} width={'400px'} position={['50%', 0]} translate={[90, 0]} />
      <Stone
        type={'A'}
        color={'blue'}
        width={'500px'}
        position={[null, null, 0, '50%', 'translateX(-50%)']}
        translate={[0, 300]}
      />

      <Helmet>
        <title>
          {intl.formatMessage({ id: 'core.page.site-switch.headline', defaultMessage: 'Choose your Organization' })} -
          smoove
        </title>
      </Helmet>

      <div className={'base-content d-flex flex-fill container-fluid position-relative'}>
        <div className={'container-fluid p-0'} style={{ maxWidth: 800 }}>
          <Row>
            <SiteSelector list={sites?.list} order={sites?.order} />
          </Row>
        </div>
      </div>
    </>
  );
};

const SiteSelector = ({ list = {}, order = [] }) => {
  if (order.length === 0) {
    return (
      <div className='d-flex flex-column align-items-center'>
        <img src={NoSitePlacehoder} className='p-4 w-50' alt="don't have access to any site" />
        <div className='mt-4 w-50 text-center text-black-50'>
          <FormattedMessage
            id='core.page.site-switch.no-site-message'
            defaultMessage='We are sorry! It seems that you do not have an active access. Please refer to your account administrator or contact mindline analytics support.'
          />
        </div>
      </div>
    );
  } else {
    const sitesTiles = order.map((sitecode, idx) => {
      return (
        <React.Fragment key={sitecode}>
          <div
            className='siteswitch'
            onClick={() => {
              userActions.switchSite(sitecode);
            }}
          >
            <div className='siteswitch__img-container'>
              <img src={`${process.env.PUBLIC_URL}${list[sitecode].imageurl}`} alt={list[sitecode].label} />
            </div>
            <div className='siteswitch__label-container'>
              <div className='siteswitch__label'>{list[sitecode].label}</div>
            </div>
          </div>
          {idx < Object.keys(list).length - 1 && <hr />}
        </React.Fragment>
      );
    });

    return (
      <Col sm={12} className='siteswitch-container'>
        <h2 className={'text-uppercase text-center font-weight-bold mb-4'}>
          <FormattedMessage id={'core.page.site-switch.headline'} defaultMessage={'Choose your Organization'} />
        </h2>
        {sitesTiles}
      </Col>
    );
  }
};
