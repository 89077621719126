export const countries = {
  DEU: {
    iso2: 'de',
    iso3: 'DEU',
    locales: ['de_DE'],
    label: 'Germany'
  },
  GBR: {
    iso2: 'gb',
    iso3: 'GBR',
    locales: ['en_GB'],
    label: 'United Kingdom'
  },
  FRA: {
    iso2: 'fr',
    iso3: 'FRA',
    locales: ['fr_FR'],
    label: 'France'
  },
  ESP: {
    iso2: 'es',
    iso3: 'ESP',
    locales: ['es_ES'],
    label: 'Spain'
  },
  USA: {
    iso2: 'us',
    iso3: 'USA',
    locales: ['en_US'],
    label: 'USA'
  },
  ITA: {
    iso2: 'it',
    iso3: 'ITA',
    locales: ['it_IT'],
    label: 'Italy'
  },
  CHE: {
    iso2: 'ch',
    iso3: 'CHE',
    locales: ['de_CH', 'fr_CH', 'it_CH'],
    label: 'Swiss'
  },
  AUS: {
    iso2: 'au',
    iso3: 'AUS',
    locales: ['en_AU'],
    label: 'Australia'
  },
  BRA: {
    iso2: 'br',
    iso3: 'BRA',
    locales: ['pt_BR'],
    label: 'Brazil'
  },
  THA: {
    iso2: 'th',
    iso3: 'THA',
    locales: ['th_TH'],
    label: 'Thailand'
  },
  MEX: {
    iso2: 'mx',
    iso3: 'MEX',
    locales: ['es_MX'],
    label: 'Mexico'
  },
  POL: {
    iso2: 'pl',
    iso3: 'POL',
    locales: ['pl_PL'],
    label: 'Poland'
  },
  TUR: {
    iso2: 'tr',
    iso3: 'TUR',
    locales: ['tr_TR'],
    label: 'Turkey'
  },
  CAN: {
    iso2: 'ca',
    iso3: 'CAN',
    locales: ['en_CA'],
    label: 'Canada'
  }
};
