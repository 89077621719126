import { routesConstants } from '../constants';

const INITIAL_STATE = {
  routes: [],
  subRoutes: []
};

export const routes = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case routesConstants.ADD:
      // const newRoutes = deduceRoutes(
      //     action.routes.subRoutes ? state.subRoutes : state.routes,
      //     action.routes.routes || action.routes.subRoutes
      // );
      //
      // if (newRoutes.length !== state.routes) {
      //     return Object.assign(state, {
      //         [action.routes.subRoutes ? 'subRoutes' : 'routes']: newRoutes
      //     });
      // }

      // console.log(action.routes);

      return {
        ...state,
        routes: [...action.routes]
      };

    default:
      return state;
  }
};
