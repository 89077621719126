import { routesConstants } from '../constants';
// import {userConstants} from "../constants";

export const routeActions = {
  add
};

function add(routes) {
  return dispatch => {
    dispatch({ type: routesConstants.ADD, routes });
  };
}
