import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { HeaderPublic, HeaderPrivate } from './index';

export const Header = () => {
  const { selectedSite, loggedIn } = useSelector(state => ({
    selectedSite: state.authentication.sites.selected,
    loggedIn: state.authentication.loggedIn
  }), shallowEqual);

  if (!loggedIn || !selectedSite) return <HeaderPublic />;
  else if (loggedIn && selectedSite) return <HeaderPrivate />;
};
