import { profileConstants } from '../constants';

const initialState = {
  loading: false,
  error: null,
  messages: null
};

export const profile = (state = initialState, action) => {
  switch (action.type) {
    case profileConstants.EDIT_USER_REQUEST:
      return {
        loading: true,
        error: null,
        messages: null
      };

    case profileConstants.EDIT_USER_SUCCESS:
      return {
        ...state,
        firstname: action.payload.firstname.value,
        lastname: action.payload.lastname.value,
        email: action.payload.email.value,
        langcode: action.payload.langcode.value,
        loading: false
      };

    case profileConstants.EDIT_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case profileConstants.SAVE_USER_REQUEST:
      return {
        loading: true,
        error: null,
        messages: null
      };

    case profileConstants.SAVE_USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false
      };

    case profileConstants.SAVE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case profileConstants.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        messages: null
      };

    case profileConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        messages: action.payload
      };

    case profileConstants.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        messages: null
      };

    default:
      return state;
  }
};
