import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, FormGroup, Label, ListGroup, ListGroupItem, Input, Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { store as notificationStore } from 'react-notifications-component';

import profile_placeholder from '../assets/img/profile_placeholder.png';
import { profileActions } from '../redux';

let Profile = ({ profile, loadProfile, saveProfile, savePassword }) => {
  const [internalProfile, setInternalProfile] = useState();
  const [changePassword, setChangePassword] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    loadProfile();
  }, [loadProfile]);

  useEffect(() => {
    if (profile?.loading === false && profile?.email !== void 0) {
      const { firstname, lastname, email, langcode } = profile;
      setInternalProfile({
        firstname,
        lastname,
        email,
        langcode,
        isModified: false
      });
    }
  }, [profile]);

  const resetProfile = useCallback(() => {
    setInternalProfile({
      ...profile,
      firstname: profile.firstname,
      lastname: profile.lastname,
      email: profile.email,
      langcode: profile.langcode,
      isModified: false
    });
  }, [setInternalProfile, profile]);

  const handleChange = ({ target }) => {
    const { name, value } = target;

    setInternalProfile({ ...profile, [name]: value, isModified: true });
    if (!!changePassword) setChangePassword(false);
  };

  const handleChangePw = ({ target }) => {
    const { name, value } = target;
    setChangePassword({ ...changePassword, [name]: value, isModified: true });
  };

  const handleSave = () => {
    savePassword(changePassword)
      .then(status => {
        if (status) {
          setChangePassword(false);
          notificationStore.addNotification({
            type: 'success',
            container: 'bottom-right',
            title: <FormattedMessage id='core.page.profile.notifications.success' defaultMessage='Success' />,
            message: (
              <FormattedMessage
                id='core.page.profile.notifications.password-change-succes-message'
                defaultMessage='Password changed.'
              />
            ),
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              showIcon: true,
              duration: 5000,
              onScreen: true,
              pauseOnHover: true
            }
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'core.menu.item.profile', defaultMessage: 'Profile' })} - smoove</title>
      </Helmet>
      <Form className='container-fluid base-content flex-fill'>
        <Row className='mb-3'>
          <Col>
            <h2 className='text-uppercase text-smv font-weight-bold'>
              <FormattedMessage id={'core.page.profile.headline'} />
            </h2>
          </Col>
        </Row>
        <Row>
          <Col md='6'>
            <div className='card p-4'>
              <p className='text-uppercase font-weight-bold'>
                <FormattedMessage id='core.page.profile.user-details' defaultMessage='User Details' />
              </p>
              <FormGroup>
                <Label for='firstName'>
                  <FormattedMessage id='common.strings.firstname' defaultMessage='Firstname' />
                </Label>
                <Input name='firstname' type='text' value={internalProfile?.firstname ?? ''} onChange={handleChange} />
              </FormGroup>

              <FormGroup>
                <Label for='lastname'>
                  <FormattedMessage id='common.strings.lastname' defaultMessage='Lastname' />
                </Label>
                <Input name='lastname' type='text' value={internalProfile?.lastname ?? ''} onChange={handleChange} />
              </FormGroup>

              {internalProfile?.isModified === true && (
                <div className='d-flex'>
                  <div className='btn btn-light ml-auto' onClick={resetProfile}>
                    <FormattedMessage id='common.buttons.abort' />
                  </div>

                  <div className='btn btn-smv-green ml-2' onClick={() => saveProfile(internalProfile)}>
                    <FormattedMessage id='common.buttons.save' />
                  </div>
                </div>
              )}
            </div>

            <div className='card p-4 mt-4'>
              <div className='d-flex'>
                <div className='text-uppercase font-weight-bold mb-1'>
                  <FormattedMessage id='common.strings.email' defaultMessage='Adresse' />
                </div>
                {/*<i className='far fa-edit p-1 ml-auto'/>*/}
              </div>
              <div>{internalProfile?.email ?? ''}</div>
            </div>

            <div className='card p-4 mt-4'>
              <div className='d-flex'>
                <div className='text-uppercase font-weight-bold mb-1'>
                  <FormattedMessage id='common.strings.password' defaultMessage='Passwort' />
                </div>
                {!changePassword && (
                  <i
                    className='far fa-edit p-1 ml-auto pointer'
                    onClick={() => setChangePassword({ pw: null, pw1: null, pw2: null })}
                  />
                )}
              </div>

              {!changePassword && (
                <>
                  <div>
                    <FormattedMessage id='core.page.profile.current-password.label' defaultMessage='Current password' />
                  </div>

                  <div>************</div>
                </>
              )}

              {!!changePassword && (
                <>
                  <FormGroup>
                    <Label for='pw'>
                      <FormattedMessage id='core.page.profile.current-password.label' defaultMessage='Current Password' />{' '}
                    </Label>
                    <Input
                      name='pw'
                      type='password'
                      value={setChangePassword.pw}
                      onChange={handleChangePw}
                      autoComplete={'pw'}
                      invalid={!!profile?.error?.pw}
                    />
                    {profile?.error?.pw && <ErrorBanner errorObj={profile.error.pw} />}
                  </FormGroup>

                  <FormGroup>
                    <Label for='pw1'>
                      <FormattedMessage id='core.page.profile.new-password' defaultMessage='New Password' />
                    </Label>
                    <Input
                      name='pw1'
                      type='password'
                      value={setChangePassword.pw1}
                      onChange={handleChangePw}
                      autoComplete={'new-pw'}
                      invalid={!!profile?.error?.pw1}
                    />
                    {profile?.error?.pw1 && <ErrorBanner errorObj={profile.error.pw1} />}
                  </FormGroup>

                  <FormGroup>
                    <Label for='pw2'>
                      <FormattedMessage
                        id='core.page.profile.new-password-repeat'
                        defaultMessage='Repeat New Password'
                      />
                    </Label>
                    <Input
                      name='pw2'
                      type='password'
                      value={setChangePassword.pw2}
                      onChange={handleChangePw}
                      autoComplete={'new-pw'}
                      invalid={!!profile?.error?.pw2}
                    />
                    {profile?.error?.pw2 && <ErrorBanner errorObj={profile.error.pw2} />}
                  </FormGroup>

                  <div className='d-flex'>
                    <div className='btn btn-light ml-auto' onClick={() => setChangePassword(false)}>
                      <FormattedMessage id='common.buttons.abort' />
                    </div>

                    <div
                      className='btn btn-smv-green ml-2'
                      onClick={() => {
                        handleSave();
                      }}
                    >
                      {profile.loading === true ? (
                        <>
                          <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true' /> saving
                        </>
                      ) : (
                        <FormattedMessage id={'common.buttons.save'} />
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </Col>

          {/** PROFILE PICTURE */}
          <Col md='4'>
            <div className='card p-4'>
              <div className='d-flex mb-2'>
                <div className='text-uppercase font-weight-bold'>
                  <FormattedMessage id='core.page.profile.profile-picture' defaultMessage='Profile picture' />
                </div>
                <i className='far fa-edit ml-auto' />
              </div>
              <img
                src={profile_placeholder}
                className='rounded-circle p-2 m-auto border'
                style={{ width: '200px', height: '200px' }}
                alt='profile'
              />
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

Profile.propTypes = {};

const mapStateToProps = state => ({
  profile: state.profile
});

const mapDispatchToProps = dispatch => ({
  loadProfile: () => profileActions.edit()(dispatch),
  saveProfile: data => profileActions.save(data)(dispatch),
  savePassword: data => profileActions.changepassword(data)(dispatch)
});

Profile = connect(mapStateToProps, mapDispatchToProps)(Profile);

export { Profile };

const ErrorBanner = ({ errorObj }) => {
  const errorMessageIDs = {
    // the keys are based on api response
    'MINMAX_TOO_SHORT_STR': { label: 'core.page.profile.current-password.error.short-password', type: 'warning' },
    'current password': {
      label: 'core.page.profile.current-password.error.incorrect-current-password',
      type: 'danger'
    },
    'general': { label: 'core.page.profile.password.error.general', type: 'warning' },
    'numbers': { label: 'core.page.profile.password.error.numbers', type: 'warning' },
    'specials': { label: 'core.page.profile.password.error.specials', type: 'warning' },
    'comparison': { label: 'core.page.profile.password.error.comparison', type: 'warning' }
  };

  const errorsDisplay = useMemo(() => {
    return Object.entries(errorObj).map(([key, value]) => {
      const errorMessage = errorMessageIDs?.[key];
      if (errorMessage)
        return (
          <ListGroupItem key={key} color={errorMessage?.type ?? 'warning'}>
            <FormattedMessage id={errorMessage?.label} defaultMessage={value} />
          </ListGroupItem>
        );
      else
        return (
          <ListGroupItem key={key} color={'warning'}>
            {value}
          </ListGroupItem>
        );
    });
  }, [errorObj, errorMessageIDs]);

  if (!errorsDisplay) return null;

  return <ListGroup className={'mt-2 small'}>{errorsDisplay}</ListGroup>;
};
