import React from 'react';
import { FormattedMessage } from 'react-intl';

export const RememberMe = ({ show = true, state, onChange }) => {
  if (!show) return null;
  return (
    <div className='custom-control custom-switch'>
      <input
        id='remembermeSwitch'
        type='checkbox'
        name='remember'
        className='custom-control-input'
        onChange={onChange}
        checked={state.remember}
      />
      <label className='custom-control-label' htmlFor='remembermeSwitch'>
        <FormattedMessage id={'core.page.login.remember.label'} defaultMessage={'Remember Login'} />
      </label>
    </div>
  );
};
