import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { ReactNotifications } from 'react-notifications-component';

import { store as _store } from './store';

import 'toolbox-common/src/assets/css/bootstrap_with_overwrites.scss';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-pro/css/all.css';
import 'typeface-roboto';
import 'react-notifications-component/dist/theme.css';
import 'animate.css';

import { ToolboxRouter } from './pages';
import { TranslationProvider, VersionCheck } from './components';

import './assets/css/app.css';
import './ToolboxShell.scss';

export const ToolboxShell = ({ store = _store, modules }) => {
  const notifications = ReactDOM.createPortal(<ReactNotifications className='top-0' />, document.body);

  return (
    <Provider store={store}>
      <TranslationProvider>
        {notifications}
        <VersionCheck>
          <PersistGate persistor={store.persistor} loading={<div>...rehydrating</div>}>
            <ToolboxRouter modules={modules} />
          </PersistGate>
        </VersionCheck>
      </TranslationProvider>
    </Provider>
  );
};
