import produce from 'immer';
import { persistReducer } from 'redux-persist';
import local from 'redux-persist/lib/storage';

import { profileConstants, userConstants } from '../constants';

const INITIAL_STATE = {
  loggedIn: false,
  loggingIn: false,
  loggingOut: false,
  loggedOut: false,

  sessionexpired: false,
  remember: false,
  error: false,

  captcha: false,
  captchaInvalid: false,
  pwexpired: false,
  accountexpired: false,
  newPasswordInvalid: false,

  sites: {
    selected: null,
    list: {},
    order: []
  },

  access: false
};

const authenticationReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    /**
     * USERS_LOGIN_X
     * USERS_RESET_PASSWORD_X
     */
    case userConstants.USERS_RESET_PASSWORD_REQUEST:
    case userConstants.USERS_LOGIN_REQUEST:
      return produce(state, draft => {
        draft.loggedIn = false;
        draft.loggingIn = true;
        draft.logginOut = false;
        draft.loggedOut = false;

        draft.sessionexpired = false;
        draft.error = null;

        draft.captchaInvalid = false;

        draft.newPasswordInvalid = false;
      });

    case userConstants.USERS_LOGIN_SUCCESS:
    case userConstants.REMEMBER_LOGIN_SUCCESS:
    case userConstants.USERS_RESET_PASSWORD_SUCCESS:
      return produce(state, draft => {
        const { acls, sites, remember } = payload;
        draft.loggedIn = true;
        draft.loggingIn = false;
        draft.logginOut = false;
        draft.loggedOut = false;

        draft.sessionexpired = false;
        draft.error = null;

        draft.access = acls;
        draft.captcha = false;

        if (remember !== undefined) {
          draft.remember = remember;
        }

        draft.sites.selected = null;
        draft.sites.list = sites;
        const order = Object.values(sites).sort((a, b) => a.label.localeCompare(b.label)).map((site) => site.code);
        draft.sites.order = order;
      });

    case userConstants.USERS_RESET_PASSWORD_FAILURE:
    case userConstants.USERS_LOGIN_FAILURE:
      return produce(state, draft => {
        const {
          captcha = false,
          captchaInvalid = false,
          newPasswordInvalid = false,
          pwexpired = false,
          accountexpired = false
        } = payload;

        draft.loggedIn = false;
        draft.loggingIn = false;
        draft.logginOut = false;
        draft.loggedOut = false;

        draft.captcha = captcha;
        draft.captchaInvalid = captchaInvalid;
        draft.newPasswordInvalid = newPasswordInvalid;
        draft.pwexpired = pwexpired;
        draft.accountexpired = accountexpired;

        // generic error: username or password incorrect
        if (!captchaInvalid && !newPasswordInvalid && !pwexpired && !accountexpired) {
          draft.error = true;
        }
      });

    /**
     * USERS_SWITCHSITE_X
     */
    case userConstants.USERS_SWITCHSITE_REQUEST:
      return produce(state, draft => {
        draft.switching = true;
      });
    case userConstants.USERS_SWITCHSITE_SUCCESS:
      return produce(state, draft => {
        const { sitecode, acls, sites } = payload;
        const order = Object.values(sites).sort((a, b) => a.label.localeCompare(b.label)).map((site) => site.code);
        draft.switching = false;
        draft.access = acls;

        // @Todo: delete parent site `smoove` for live system
        //       The parant site only handles the first login,
        //       all data is stored in the subsites
        // delete sites.smoove;
        draft.sites.selected = sitecode;
        draft.sites.list = sites;
        draft.sites.order = order;
      });
    case userConstants.USERS_SWITCHSITE_FAILURE:
      return produce(state, draft => {
        draft.switching = false;
      });

    /**
     * USERS_LOGOUT_X
     */
    case userConstants.USERS_LOGOUT_REQUEST:
      return produce(state, draft => {
        draft.loggingOut = true;
      });

    case userConstants.USERS_LOGOUT_SUCCESS:
      return {
        ...INITIAL_STATE,
        loggedOut: true
      };

    case userConstants.USERS_LOGOUT_FAILURE:
      return produce(state, draft => {
        draft.loggedOut = false;
        draft.loggingOut = false;
        draft.error = 'Logout failed!';
      });

    /**
     * USERS_DETAILS_X
     */
    case userConstants.USERS_DETAILS_REQUEST:
      return state;

    case userConstants.USERS_DETAILS_SUCCESS:
      return produce(state, draft => {
        const { user } = payload;
        draft.user = user;
      });

    case userConstants.USERS_DETAILS_FAILURE:
      return state;

    /**
     * USERS_SESSION_EXPIRED
     */
    case userConstants.USERS_SESSION_EXPIRED:
      return {
        ...INITIAL_STATE,
        sessionexpired: true
      };

    /**
     * USERS_SESSION_EXPIRED
     */
    case userConstants.REMEMBER_LOGIN_REQUEST:
      return produce(state, draft => {
        draft.loggingOut = false;
        draft.loggedOut = false;
        draft.loggingIn = true;
      });

    case userConstants.REMEMBER_LOGIN_FAILURE:
      return produce(state, draft => {
        draft.sessionexpired = true;
        draft.loggingIn = false;
        draft.remember = false;
      });

    case profileConstants.SAVE_USER_SUCCESS:
      return { ...state, user: payload };

    default:
      return state;
  }
};

const persistConfig = {
  key: 'authentication',
  storage: local,
  whitelist: ['loggedIn', 'remember', 'sites']
};

export const authentication = persistReducer(persistConfig, authenticationReducer);
