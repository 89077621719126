import React from 'react';
import { render } from 'react-dom';
import { ToolboxShell } from 'toolbox-shell';
import 'typeface-roboto';
import packageJson from '../package.json';

const version = packageJson.version;
global.REACT_APP_VERSION = version;

const wizards = [];
if (process.env.REACT_APP_WZ_INDIVIDUAL === 'true') {
  wizards.push({
    path: '/one/project/create/individual/:step?',
    content: () => import(/* webpackChunkName: "wizard-individual" */ 'wizards-shared/src/individual')
  });
}

if (process.env.REACT_APP_WZ_CONCEPT === 'true') {
  wizards.push({
    path: '/one/project/create/concept/:step?',
    content: () => import(/* webpackChunkName: "wizard-concept" */ 'wizards-shared/src/concept/module')
  });
}
if (process.env.REACT_APP_WZ_DESIGN === 'true') {
  wizards.push({
    path: '/one/project/create/design/:step?',
    content: () => import(/* webpackChunkName: "wizard-design" */ 'wizards-shared/src/CreateProjectWizardDesign')
  });
}

wizards.push({
  path: '/one/project/create/impact/:type?/:step?',
  content: () => import(/* webpackChunkName: "wizard-impact" */ 'wizards-beiersdorf/src/impact')
});

wizards.push({
  path: '/one/project/create/bdf/crowner/:step?',
  content: () => import(/* webpackChunkName: "wizard-bdf-crowner" */ 'wizards-beiersdorf/src/crowner')
});

if (process.env.REACT_APP_WZ_BURGER_KING === 'true') {
  wizards.push({
    path: '/one/project/create/burgerking-taste/:step?',
    content: () => import(/* webpackChunkName: "burger-king" */ 'wizards-burger-king/src')
  });
}
if (process.env.REACT_APP_WZ_SCHWAN_COSMETICS === 'true') {
  wizards.push({
    path: '/one/project/create/schwan-concept/:step?',
    content: () => import(/* webpackChunkName: "wizard-schwan-concept" */ 'wizards-schwan-cosmetics/src')
  });
}

export function SmooveModule() {
  return (
    <ToolboxShell
      modules={[
        {
          path: '/one',
          content: () => import(/* webpackChunkName: "smoove" */ './smoove/'),
          wizards
        }
      ]}
    />
  );
}

document.getElementById('root') && render(<SmooveModule />, document.getElementById('root'));
