import React, { useEffect, useState, useRef, useCallback } from 'react';
import { CustomInput, Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';

import './multipleselect.scss';

export const MultipleSelectDropdown = ({
  options,
  values = [],
  onChange,
  placeholder = 'select',
  readOnly = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [internalValues, setInternalValues] = useState(values ?? []);
  const [selectedElements, setSelectedElements] = useState([]);

  useEffect(() => {
    if (onChange && !isEqual(values, internalValues)) {
      onChange(internalValues);
    }

    setSelectedElements(
      options
        .filter(item => internalValues.includes(item.id))
        .map(item => (
          <div
            key={item.id}
            className={`multipleselect__selected-item ${readOnly ? 'multipleselect__selected-item--disabled' : ''}`}
          >
            {!readOnly && (
              <span
                className={`multipleselect__selected-item-delete`}
                onClick={e => {
                  e.stopPropagation();
                  !readOnly ? setInternalValues(internalValues.filter(value => value !== item.id)) : () => {};
                }}
              >
                &times;
              </span>
            )}
            <div className='multipleselect__selected-item-label'>{item.label}</div>
          </div>
        ))
    );
  }, [internalValues, onChange]);

  const toggleOpened = () => setIsOpen(prevState => !prevState);

  const handleChange = (item, isSelected) => {
    if (isSelected) setInternalValues(prevState => prevState.filter(id => id !== item.id));
    else setInternalValues(prevState => [...prevState, item.id]);
  };

  //calculate width of container to show the correct amount of selectedElements
  //one element shoud be about 80px wide
  let multiselectContainer = useRef(null);
  let maxSelectedElements = Math.floor((multiselectContainer.current?.offsetWidth - 60) / 80);

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={toggleOpened}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <DropdownToggle tag={'div'} caret={false}>
        <div
          ref={multiselectContainer}
          className={`multipleselect__selected-container ${
            selectedElements.length === 0 ? 'multipleselect__selected-container--empty' : ''
          }`}
        >
          {selectedElements.length === 0 && placeholder}
          {selectedElements.length <= maxSelectedElements && selectedElements}
          {selectedElements.length > maxSelectedElements && (
            <>
              {selectedElements.map((el, idx) => {
                return idx < maxSelectedElements ? el : null;
              })}
              <div className='multipleselect__additional-items'>+{selectedElements.length - maxSelectedElements}</div>
            </>
          )}
        </div>
      </DropdownToggle>

      <DropdownMenu className='multipleselect__dropdown-menu'>
        {options.map(item => (
          <DropdownItem
            disabled={readOnly}
            key={item.id}
            tag={'div'}
            toggle={false}
            style={{ padding: 0 }}
            onClick={!readOnly ? () => handleChange(item, internalValues.includes(item.id)) : () => {}}
          >
            <div style={{ width: '100%', height: '100%', padding: '6px 12px' }}>
              <CustomInput
                disabled={true}
                type='checkbox'
                id={item.id.toString()}
                label={item.label}
                checked={internalValues.includes(item.id)}
              />
            </div>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

MultipleSelectDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};
