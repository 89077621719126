import Axios from 'axios';

import { store } from 'toolbox-shell/src/store';
import { settingsConstants } from '../constants';

/**
 * Sets the application locale of the frontend
 * @param {string} locale Locale with unterscore, e.g. en_US
 */
const setAppLocale = locale => {
  store.dispatch({
    type: settingsConstants.APP_SETTINGS_SET_LOCALE_SUCCESS,
    payload: { locale }
  });
};

/**
 * Fetches meta.json from the backend to load code base version and branch.
 */
const checkAppVersion = () => {
  store.dispatch({
    type: settingsConstants.APP_SETTINGS_CHECK_VERSION_REQUEST,
    payload: {}
  });

  Axios.get(`/meta.json?${Date.now()}`, { baseURL: process.env?.PUBLIC_URL ?? '' })
    .then(({ data }) => {
      const { version, branch } = data;

      store.dispatch({
        type: settingsConstants.APP_SETTINGS_CHECK_VERSION_SUCCESS,
        payload: { version, branch }
      });
    })
    .catch(err => {
      store.dispatch({
        type: settingsConstants.APP_SETTINGS_CHECK_VERSION_FAILURE,
        payload: { error: err }
      });
    });
};

export const settingsActions = {
  checkAppVersion,
  setAppLocale
};
