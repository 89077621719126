/**
 * Removes html tags from string or array of strings
 * @param  {string | string[]} input
 *
 * @return {string | string[]} stripedHtml
 */
export const stripHtml = input => {
  const regExp = /<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g;

  if (Array.isArray(input)) {
    return input.map(item => item.replace(regExp, ''));
  }

  if (typeof input === 'string') {
    return input?.replace(regExp, '');
  }

  return input;
};
