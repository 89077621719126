import React, { useState, useEffect, useCallback } from 'react';
import { Link, NavLink as RRNavLink, useRouteMatch } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import classNames from 'classnames';

import { settingsActions, userActions } from '../redux';
import { HeaderLogo, useScrollOffset } from '.';
import './Header.scss';
import 'flag-icon-css/css/flag-icon.min.css';

const menuLinks = [
  {
    to: '/one',
    exact: true,
    activeClassName: 'active',
    messageId: 'core.menu.item.dashboard',
    defaultMessage: 'Overview'
  },
  {
    to: '/one/projects',
    exact: true,
    activeClassName: 'active',
    messageId: 'core.menu.item.projects',
    defaultMessage: 'My Projects'
  }
];

const subMenuLinks = [
  {
    to: '/general',
    exact: false,
    activeClassName: 'active',
    messageId: 'core.menu.submenu.item.general',
    defaultMessage: 'General'
  },
  {
    to: '/que',
    exact: false,
    activeClassName: 'active',
    messageId: 'core.menu.submenu.item.questionnaire',
    defaultMessage: 'Questionnaire'
  },
  {
    to: '/data',
    exact: false,
    activeClassName: 'active',
    messageId: 'core.menu.submenu.item.data',
    defaultMessage: 'Data'
  },
  {
    to: '/tables',
    exact: false,
    activeClassName: 'active',
    messageId: 'core.menu.submenu.item.tables',
    defaultMessage: 'Tables'
  },
  {
    to: '/reports',
    exact: false,
    activeClassName: 'active',
    messageId: 'core.menu.submenu.item.reports',
    defaultMessage: 'Reports'
  }
];

export const HeaderPrivate = () => {
  const { sites, user, locale, userAccess } = useSelector(state => ({
    sites: state.authentication.sites,
    user: state.authentication.user,
    locale: state.appSettings.locale,
    userAccess: state?.smooveSettings?.access ?? {}
  }), shallowEqual);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [projectMenuIsOpen, setProjectMenuIsOpen] = useState(false);

  const switchSite = (sitecode) => {
    if (sitecode !== sites.selected) {
      userActions.switchSite(sitecode)
    }
  }

  const match = useRouteMatch('/one/projects/:projectid/:surveyid');
  useEffect(() => {
    match?.params?.projectid ? setProjectMenuIsOpen(true) : setProjectMenuIsOpen(false);
  }, [match]);

  const shouldShrink = useScrollOffset(30);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', toggle, true);
    }
    return () => {
      document.removeEventListener('click', toggle, true);
    };
    // document.body.classList.toggle('overflow-hidden', isOpen);
  }, [isOpen]);

  return (
    <div
      className={classNames('smv-header container-fluid fixed-top', {
        'box-shadow': shouldShrink,
        'smv-header--shrinked': shouldShrink,
        'smv-header--projectmenu-open': projectMenuIsOpen,
        'smv-header--mobilemenu-open': isOpen
      })}
    >
      <Navbar light expand='lg' className='smv-header__navbar'>
        <NavbarBrand tag={Link} to={'/switch'}>
          <HeaderLogo animate={true} />
        </NavbarBrand>

        <div onClick={toggle}>
          {!isOpen && <NavbarToggler />}
          {isOpen && (
            <div className='navbar-close'>
              <i className='fal fa-times' />
            </div>
          )}
        </div>
        <Collapse isOpen={isOpen} navbar className='smv-header__collapse'>
          <Nav className='navbar-nav' navbar>
            {menuLinks.map(({ messageId, defaultMessage, ...rest }) => (
              <NavItem key={rest.to} className='menu-item'>
                <NavLink
                  {...rest}
                  tag={RRNavLink}
                  className='d-flex h-100 align-items-center text-uppercase text-smv'
                  draggable={false}
                >
                  <FormattedMessage id={messageId} defaultMessage={defaultMessage} />
                </NavLink>
              </NavItem>
            ))}
            {match && projectMenuIsOpen && (
              <div className={`smv-header__projectmenu ${projectMenuIsOpen ? 'smv-header__projectmenu--active' : ''}`}>
                <div className='smv-header__menuitem-separator'></div>
                {subMenuLinks.map(({ messageId, defaultMessage, to, ...rest }) => {
                  if (to === "/data" && !userAccess.canEditDataPreparation) {
                    return null
                  } else {
                    return <NavItem key={to} className='menu-item'>
                      <NavLink
                        to={match.url + to}
                        tag={RRNavLink}
                        className='d-flex h-100 align-items-center text-uppercase text-smv'
                        draggable={false}
                        {...rest}
                      >
                        <FormattedMessage id={messageId} defaultMessage={defaultMessage} />
                      </NavLink>
                    </NavItem>
                  }
                })}
              </div>
            )}
          </Nav>
          {user && (
            <>
              <UncontrolledDropdown inNavbar className='smv-header__dropdown'>
                <DropdownToggle nav className='text-smv-red text-uppercase' draggable={false}>
                  {user.firstname}
                  <i className='fas fa-chevron-down pl-1' />
                </DropdownToggle>

                <DropdownMenu right>

                  <RRNavLink to={'/profile'} exact={true} activeClassName='active' draggable={false}>
                    <DropdownItem className='text-smv'>
                      <FormattedMessage id={'core.menu.item.profile'} defaultMessage={'Profile'} />
                    </DropdownItem>
                  </RRNavLink>

                  <RRNavLink to={'/one/settings'} exact={true} activeClassName='active' draggable={false}>
                    <DropdownItem className='text-smv'>
                      <FormattedMessage id={'core.menu.item.settings'} defaultMessage={'Settings'} />
                    </DropdownItem>
                  </RRNavLink>

                  <DropdownItem className='p-0'>
                    <div className='dropleft dropdown-item dropdown-hover text-smv'>
                      <div
                        className='dropdown-toggle'
                        id='navbarDropdownLanguage'
                        role={'button'}
                        data-toggle='dropdown'
                        aria-haspopup='true'
                        aria-expanded='false'
                      >
                        <FormattedMessage id={'core.menu.item.language'} defaultMessage={'Language'} />
                      </div>
                      <div className='dropdown-menu dropdown-menu--language' aria-labelledby='navbarDropdownLanguage'>
                        <div
                          className={`dropdown-item ${locale === 'de_DE' ? 'active' : ''}`}
                          onClick={() => settingsActions.setAppLocale('de_DE')}
                        >
                          <span className={`flag-icon flag-icon-de mr-2`} />
                          <span>
                            <FormattedMessage id={'core.languages.german'} defaultMessage={'German'} />
                          </span>
                        </div>
                        <div
                          className={`dropdown-item ${locale === 'en_US' ? 'active' : ''}`}
                          onClick={() => settingsActions.setAppLocale('en_US')}
                        >
                          <span className={`flag-icon flag-icon-us mr-2`} />
                          <span>
                            <FormattedMessage id={'core.languages.english'} defaultMessage={'English'} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </DropdownItem>

                  <DropdownItem divider />

                  {sites?.order?.length > 1 && (
                    <>
                      <DropdownItem className='p-0'>
                        <div className='dropleft dropdown-item dropdown-hover text-smv'>
                          <div
                            className='dropdown-toggle'
                            id='navbarDropdownSiteSwitch'
                            role={'button'}
                            data-toggle='dropdown'
                            aria-haspopup='true'
                            aria-expanded='false'
                          >
                            <FormattedMessage id={'core.menu.item.site-switch'} defaultMessage={'Switch Organization'} />
                          </div>
                          <div
                            className='dropdown-menu dropdown-menu--language'
                            aria-labelledby='navbarDropdownSiteSwitch'
                          >
                            {sites.order.map(sitecode => (
                              <DropdownItem
                                key={sitecode}
                                disabled={sitecode === sites.selected}
                                tag={'div'}
                                className={classNames('text-smv', { active: sitecode === sites.selected })}
                                onClick={() => switchSite(sitecode)}
                              >
                                {sites.list[sitecode].label}
                              </DropdownItem>
                            ))}
                          </div>
                        </div>
                      </DropdownItem>
                      <DropdownItem divider />
                    </>
                  )}

                  {userAccess.canEditUsers && (
                    <>
                      <RRNavLink to={'/one/users'} exact={true} activeClassName='active' draggable={false}>
                        <DropdownItem className='text-smv'>
                          <FormattedMessage id={'core.menu.item.user-management'} defaultMessage={'User Management'} />
                        </DropdownItem>
                      </RRNavLink>
                      <DropdownItem divider />
                    </>
                  )}

                  <RRNavLink to={'/logout'} exact={true} draggable={false}>
                    <DropdownItem className='text-smv'>
                      <FormattedMessage id={'core.menu.item.logout'} defaultMessage={'Logout'} />
                    </DropdownItem>
                  </RRNavLink>

                </DropdownMenu>
              </UncontrolledDropdown>
              {/* user-dropdown content on mobile */}
              <div className='smv-header__usermenu-mobile'>
                <div className='d-flex'>
                  <div
                    className={`usermenu-mobile__flag ${locale === 'de_DE' ? 'usermenu-mobile__flag--active' : ''}`}
                    onClick={() => settingsActions.setAppLocale('de_DE')}
                  >
                    <span className={`flag-icon flag-icon-de`} />
                  </div>
                  <div
                    className={`usermenu-mobile__flag ${locale === 'en_US' ? 'usermenu-mobile__flag--active' : ''}`}
                    onClick={() => settingsActions.setAppLocale('en_US')}
                  >
                    <span className={`flag-icon flag-icon-us`} />
                  </div>
                </div>
                {Object.keys(sites.list).length > 1 && (
                  <div className="usermenu-mobile__organization">
                    <DropdownItem divider className="w-100 my-2" />
                    <div className="usermenu-mobile__organization-headline"><FormattedMessage id={'core.menu.item.organizations'} defaultMessage={'Organizations'} /></div>
                    {Object.keys(sites.list).map(sitecode => (
                      <div
                        key={sitecode}
                        tag={'div'}
                        className={classNames('text-smv', { active: sitecode === sites.selected })}
                        onClick={() => switchSite(sitecode)}
                      >
                        {sites.list[sitecode].label}
                      </div>
                    ))}
                    <DropdownItem divider className="w-100 my-2" />
                  </div>
                )}
                <RRNavLink to={'/profile'} exact={true} activeClassName='active' draggable={false}>
                  <FormattedMessage id={'core.menu.item.profile'} defaultMessage={'Profile'} />
                </RRNavLink>
                <RRNavLink to={'/one/settings'} exact={true} activeClassName='active' draggable={false}>
                  <FormattedMessage id={'core.menu.item.settings'} defaultMessage={'Settings'} />
                </RRNavLink>
                <RRNavLink to={'/logout'} exact={true} draggable={false}>
                  <FormattedMessage id={'core.menu.item.logout'} defaultMessage={'Logout'} />
                </RRNavLink>
              </div>
            </>
          )}
        </Collapse>
      </Navbar>
    </div>
  );
};
