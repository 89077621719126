import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, FormFeedback, FormGroup, Input, Label } from 'reactstrap';

export const Captcha = ({ show = false, state, invalid = false, onChange = null }) => {
  const intl = useIntl();

  const captchaUrl = useMemo(() => {
    const baseUrl = process.env?.REACT_APP_API ?? '';
    return `${baseUrl}/captcha.php`;
  })

  if (!show) return null;

  return (
    <FormGroup>
      <h6 className={'text-smv text-uppercase font-weight-bold pt-5'}>
        <FormattedMessage id={'core.page.login.captcha.label'} defaultMessage={'Security Characters'} />
      </h6>
      <FormGroup row>
        <Col>
          <Input
            id={'captcha'}
            type={'text'}
            name={'captcha'}
            autoComplete={'off'}
            className={'question'}
            placeholder={intl.formatMessage({ id: 'core.page.login.captcha.placeholder', defaultMessage: 'enter characters...' })}
            value={state.captcha}
            onChange={onChange}
            invalid={invalid}
            required
          />
          <Label htmlFor={'captcha'}>
            <span />
          </Label>

          {invalid && (
            <FormFeedback>
              <FormattedMessage id={'core.page.login.error.captcha-invalid'} />
            </FormFeedback>
          )}
        </Col>

        <div className={'col'}>
          <img src={captchaUrl} alt={'captcha'} />
        </div>
      </FormGroup>
    </FormGroup>
  );
};
