import axios from 'axios';

import { profileConstants } from '../constants';
import 'toolbox-shell/src/axiosConfig';

const edit = () => {
  return dispatch => {
    dispatch({ type: profileConstants.EDIT_USER_REQUEST });

    const pca = {
      program: 'User',
      controller: 'XUser',
      action: 'edit'
    };

    return axios
      .get(`/jsonrpc.php`, { params: pca })
      .then(editResponse => {
        const user = editResponse.data.result.program.User.XUser.edit.data;
        dispatch({ type: profileConstants.EDIT_USER_SUCCESS, payload: user });
      })
      .catch(err => {
        dispatch({
          type: profileConstants.EDIT_USER_FAILURE,
          payload: 'Something went wrong while fetching user edit data'
        });
      });
  };
};

const save = profile => {
  return dispatch => {
    dispatch({ type: profileConstants.SAVE_USER_REQUEST });

    const data = getFormdata(profile);
    const config = {
      params: {
        program: 'User',
        controller: 'XUser',
        action: 'save'
      }
    };

    axios
      .post(`/jsonrpc.php`, data, config)
      .then(result => {
        if (result.data.result?.program?.User?.XUser?.save?.status === true) {
          dispatch({
            type: profileConstants.SAVE_USER_SUCCESS,
            payload: profile
          });
        } else {
          dispatch({ type: profileConstants.SAVE_USER_FAILURE });
        }
      })
      .catch(error =>
        dispatch({
          type: profileConstants.SAVE_USER_FAILURE,
          payload: [error.message]
        })
      );
  };
};

const changepassword = passwords => {
  const { pw, pw1, pw2 } = passwords;

  return dispatch => {
    dispatch({ type: profileConstants.CHANGE_PASSWORD_REQUEST });

    const pca = {
      program: 'User',
      controller: 'XUser',
      action: 'changepassword'
    };

    const fd = new FormData();
    fd.append('pw', pw);
    fd.append('pw1', pw1);
    fd.append('pw2', pw2);

    return axios.post(`/jsonrpc.php`, fd, { params: pca }).then(
      editResponse => {
        const actionResult = editResponse.data.result.program.User.XUser.changepassword;

        if (actionResult.status === true) {
          dispatch({
            type: profileConstants.CHANGE_PASSWORD_SUCCESS,
            payload: [
              {
                type: 'success',
                message: 'Änderung gespeichert!'
              }
            ]
          });

          return true;
        } else {
          let errors = {};

          // eslint-disable-next-line
          for (const dataKey in actionResult.data) {
            if (actionResult.data[dataKey].hasOwnProperty('errors')) {
              errors[dataKey] = { ...actionResult.data[dataKey].errors };
            }
          }
          dispatch({
            type: profileConstants.CHANGE_PASSWORD_FAILURE,
            payload: errors
          });

          return Promise.reject(errors);
        }
      },
      error => {
        console.log('lala', error);
      }
    );
  };
};

export const profileActions = {
  edit,
  save,
  changepassword
};

const getFormdata = (data, stringify = true) => {
  const formData = new FormData();

  // eslint-disable-next-line
  for (const key in data) {
    if (typeof data[key] !== 'string' && stringify === true) {
      data[key] = JSON.stringify(data[key]);
    }

    formData.append(key, data[key]);
  }

  return formData;
};
