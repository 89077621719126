import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useIntl } from 'react-intl';

export const ConfirmAction = ({ callback, body, title, children, size = null, btnColor = "danger",
btnText = null }) => {
  const [isOpened, setIsOpened] = useState(false);

  const intl = useIntl();

  const handler = {
    show: () => setIsOpened(true),
    hide: () => setIsOpened(false),
    confirm: () => {
      callback();
      handler.hide();
    }
  };

  return (
    <>
      {children(handler.show)}
      <Modal isOpen={isOpened} centered={true} size={size} autoFocus={false}>
        <ModalHeader children={title} />
        <ModalBody children={body} />
        <ModalFooter>
          <Button color={"secondary"} outline={true} onClick={handler.hide}>
            {intl.formatMessage({ id: `common.buttons.cancel` })}
          </Button>
          <Button color={btnColor} onClick={handler.confirm} autoFocus={true}>
            {btnText ?? intl.formatMessage({ id: `common.buttons.delete` })}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
