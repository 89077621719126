import React, { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { isEqual } from 'lodash';

import { Stone } from 'toolbox-common';

import { userActions } from '../redux';
import { Username, Password, RememberMe, Captcha } from '../components/Login';

import LoginIcon from './login.png';
import { Button } from 'reactstrap';

export const Login = () => {
  const { user } = useSelector(state => ({
    user: state.authentication
  }));

  const [state, setState] = useState({
    username: '',
    password: '',
    captcha: '',
    newPassword1: '',
    newPassword2: '',
    remember: user?.remember ?? false
  });

  const intl = useIntl();

  /**
   * Check form state, if all required fields are filled
   */
  const canSubmit = useMemo(() => {
    if (state.username.length === 0 || state.password.length === 0) return false;
    else if (user.pwexpired && (state.newPassword1.length <= 0 || !isEqual(state.newPassword1, state.newPassword2)))
      return false;
    else if (user.captcha && state.captcha.length !== 5) return false;
    return true;
  }, [user, state]);

  /**
   * Generic form field handler
   */
  const handleChange = useCallback(e => {
    let { name, value, type } = e.target;
    if (type === 'checkbox') value = e.target.checked;
    setState(s => ({ ...s, [name]: value }));
  }, []);

  /**
   * Handle form submit, either login or change password event
   */
  const handleSubmit = e => {
    e.preventDefault();

    if (!canSubmit) return false;

    if (!user.pwexpired) {
      // The success is handled through ToolboxRouter,
      // which checks for the updated state and redirects
      // to a new location
      userActions.login({
        username: state.username,
        password: state.password,
        remember: state.remember,
        captcha: state.captcha.length > 0 ? state.captcha : false
      });
    } else {
      userActions.resetPassword({
        username: state.username,
        pw: state.password,
        pw1: state.newPassword1,
        pw2: state.newPassword2,
        remember: state.remember,
        captcha: state.captcha
      });
    }

    // reset captcha after submit
    setState(s => ({ ...s, captcha: '' }));
  };

  return (
    <>
      <Stone type={'D'} color={'green'} width={'400px'} position={['20%', null, null, 0]} translate={[-250, 0]} />
      <Stone type={'B'} color={'orange'} width={'400px'} position={['50%', 0]} translate={[90, 0]} />
      <Stone
        type={'A'}
        color={'blue'}
        width={'500px'}
        position={[null, null, 0, '50%', 'translateX(-50%)']}
        translate={[0, 300]}
      />

      <Helmet>
        <title>{intl.formatMessage({ id: 'core.page.login.headline', defaultMessage: 'Login' })} - smoove</title>
      </Helmet>

      <div className='base-content d-flex flex-fill container-fluid position-relative'>
        <div className={'container-fluid p-0'} style={{ maxWidth: 800 }}>
          {/* Login Icon */}
          <img src={LoginIcon} style={{ position: 'absolute', transform: 'translate(-150px,0px)' }} alt='' />

          {/* Headline */}
          <h2 className='text-uppercase text-smv-red font-weight-bold'>
            <FormattedMessage id={'core.page.login.headline'} defaultMessage={'Login'} />
          </h2>

          {user.error && (
            <div className='alert alert-danger mt-4 mb-2'>
              <FormattedMessage id={'core.page.login.error.generic'} />
            </div>
          )}
          {user.accountexpired && (
            <div className='alert alert-danger mt-4 mb-2'>
              <FormattedMessage id={'core.page.login.error.accountexpired'} />
            </div>
          )}
          {user.sessionexpired && (
            <div className='alert alert-danger mt-4 mb-2'>
              <FormattedMessage id={'core.page.login.error.sessionexpired'} />
            </div>
          )}

          {/* Login form */}
          <form onSubmit={handleSubmit} className={'pt-5 mt-5'}>
            <Username state={state} onChange={handleChange} />

            <Password state={state} onChange={handleChange} />

            {user.pwexpired && (
              <>
                <div className='row alert alert-warning mt-5 mb-3'>
                  <FormattedMessage
                    id={'core.page.login.error.password-expired'}
                    defaultMessage={'Your password has expired, please set a new password.'}
                  />
                </div>
                <Password
                  name={'newPassword1'}
                  state={state}
                  invalid={user.newPasswordInvalid}
                  onChange={handleChange}
                />
                <Password
                  name={'newPassword2'}
                  state={state}
                  invalid={user.newPasswordInvalid}
                  onChange={handleChange}
                />
              </>
            )}

            <Captcha show={user.captcha} state={state} invalid={user.captchaInvalid} onChange={handleChange} />
            <RememberMe show={true} state={state} onChange={handleChange} />

            <Button color={"primary"} className='mt-4' type='submit' style={{ minWidth: 250 }} disabled={!canSubmit}>
              {user.loggingIn && (
                <>
                  <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true' />{' '}
                  {intl.formatMessage({ id: 'core.page.login.button.loading', defaultMessage: 'loading...' })}
                </>
              )}
              {!user.loggingIn && !user.pwexpired && (
                <FormattedMessage id={'core.page.login.button.submit'} defaultMessage={'Sign in'} />
              )}
              {!user.loggingIn && user.pwexpired && (
                <FormattedMessage id={'core.page.login.button.save'} defaultMessage={'Save'} />
              )}
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};
