import React from 'react';
import { useIntl } from 'react-intl';

import { TextInputRow } from 'toolbox-common/src';

export const Password = ({ state, onChange, name = 'password', invalid = false }) => {
  const intl = useIntl();
  let formText = null;

  if (name === 'newPassword2') {
    formText =
      intl.formatMessage({ id: 'core.page.login.newPassword.instructions' }) + ' !"#$%&\'*+,./:;=?@^_`|~-({[<>]})';
  }

  return (
    <TextInputRow
      name={name}
      type={'password'}
      title={intl.formatMessage({ id: `core.page.login.${name}.label`, defaultMessage: 'Password' })}
      value={state[name] ?? ''}
      placeholder={intl.formatMessage({
        id: `core.page.login.${name}.placeholder`,
        defaultMessage: 'Enter password'
      })}
      formText={formText}
      change={onChange}
      invalid={invalid}
      formFeedback={intl.formatMessage({ id: 'core.page.login.error.password-invalid' })}
      required
    />
  );
};
