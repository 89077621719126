import { settings } from './settingsReducer';
import { routes } from './routesReducer';
import { authentication } from './authenticationReducer';
import { alert } from './alertReducer';
import { profile } from './profileReducer';
// import { registration } from './registration.reducer';
// import { users } from './users.reducer';

export const coreReducers = {
  appSettings: settings,
  routes,
  authentication,
  profile,
  alert
};
