import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { persistStore } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import { createBrowserHistory } from 'history';

import { coreReducers } from './redux';

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});

const createRootReducer = asyncReducers =>
  combineReducers({
    ...coreReducers,
    ...asyncReducers
  });

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(createRootReducer(), composeEnhancers(applyMiddleware(...[thunkMiddleware])));

// Add a dictionary to keep track of the registered async reducers
store.asyncReducers = {};
store.persistor = persistStore(store);

store.injectReducers = reducers => {
  reducers.forEach(({ name, fn }) => {
    store.asyncReducers[name] = fn;
  });

  // Flush Persists the pending state to avoid the loss of state as
  // replaceReducer causes a rehydrate
  store.persistor.flush();
  store.replaceReducer(createRootReducer(store.asyncReducers));
};
